import MKBox from "components/MKBox";
import bgImage from "assets/images/project/work_profile_1.png";
import b1 from "assets/images/project/b1.png";
import b2 from "assets/images/project/b2.png";
import b3 from "assets/images/project/b3.png";
import b4 from "assets/images/project/b4.png";
import b5 from "assets/images/project/b5.png";
import b6 from "assets/images/project/b6.png";
import MKTypography from "components/MKTypography";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation,Autoplay } from 'swiper/modules';

function Cover({items}) {
  return (
    <>
    {items && items.length > 0 && (
      <Swiper navigation={true} modules={[Navigation, Autoplay]} className="mySwiper"
        style={{ height: '100%', backgroundColor: "white", 
        boxShadow: '0px 10px 15px -5px rgba(0, 0, 0, 0.5)'
      }}
        spaceBetween={10}
        slidesPerView={1}
        loop={true}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 2000, 
          disableOnInteraction: false, 
        }}
        speed={2000} // Transition speed (1 second)
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 2,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
        }}
      >
        {items.map((banner, index) => (
          <SwiperSlide key={index}>
            <MKBox
              style={{
                backgroundImage: `url(${process.env.REACT_APP_IMG_URL + banner.logo})`,
                backgroundSize: 'contain',  // Scale image to fit inside slide without cropping
                backgroundRepeat: 'no-repeat', // Prevent background tiling
                backgroundPosition: 'center',
                minHeight: '400px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#fff',
              }}
            >
              {/* Optional Slide Content */}
            </MKBox>
            <MKTypography variant="h6" align="center" mb={1}>
              {banner.name}
            </MKTypography>
          </SwiperSlide>
        ))}
      </Swiper>
      
      )}
    </>         
  );
}

export default Cover;
